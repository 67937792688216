<template>
  <div
    class="shop-header"
    :class="$$FormData.show_content"
    :style="{
      backgroundColor: $$FormData.background_color,
      color: $$FormData.text_color
    }"
  >
    <img src="./default-shop-logo.png" alt class="shop-logo"/>
    <div class="shop-content">
      <div class="shop-primary-row">
        <div class="name-block">
          <div class="name">店铺名称</div>
          <div
            v-if="$$FormData.switch_branch"
            class="shop-icon"
            :style="{backgroundImage: `url('${ShopIcons.getIcon(ShopIcons.arrowDown, $$FormData.text_color)}')`}"
          />
        </div>
        <span v-if="$$FormData.show_content === 'show-address'">距离: 100m</span>
      </div>
      <!--显示具体位置-->
      <div v-if="$$FormData.show_content === 'show-address'" class="shop-sub-row">
        <div class="sub">北京市西城区西长安街人民大会堂</div>
        <div class="icon-nav" :style="{borderColor: $$FormData.text_color}">
          <div
            class="shop-icon"
            :style="{backgroundImage: `url('${ShopIcons.getIcon(ShopIcons.navigation, $$FormData.text_color)}')`}"
          />
        </div>
      </div>
      <!--显示配送方式-->
      <div v-if="$$FormData.show_content === 'show-ship-type'" class="shop-sub-row">
        <div class="sub-flex-item">
          <div
            class="shop-icon"
            :style="{backgroundImage: `url('${ShopIcons.getIcon(ShopIcons.ok, $$FormData.text_color)}')`}"
          />
          到店自提
        </div>
        <div class="sub-flex-item">
          <div
            class="shop-icon"
            :style="{backgroundImage: `url('${ShopIcons.getIcon(ShopIcons.ok, $$FormData.text_color)}')`}"
          />
          配送到家
        </div>
        <div class="sub-flex-item">
          <div
            class="shop-icon"
            :style="{backgroundImage: `url('${ShopIcons.getIcon(ShopIcons.ok, $$FormData.text_color)}')`}"
          />
          快递发货
        </div>
      </div>
      <!--只显示联系方式-->
      <div v-if="$$FormData.show_content === 'only-contact-way'" class="shop-sub-row">
        <div class="sub">TEL:18888888888</div>
        <div class="icon-nav" :style="{borderColor: $$FormData.text_color}">
          <div
            class="shop-icon"
            :style="{backgroundImage: `url('${ShopIcons.getIcon(ShopIcons.tel, $$FormData.text_color)}')`}"
          />
        </div>
      </div>
      <!--显示营业时间-->
      <div v-if="$$FormData.show_content === 'show-business-time'" class="shop-sub-row">
        营业时间：08:00-20:00
      </div>
    </div>
  </div>
</template>

<script>
import * as ShopIcons from './ShopIcons'

export default {
  name: 'ShopHeaderPreview',
  data() {
    return { ShopIcons }
  }
}
</script>

<style lang="scss" scoped>
.shop-header {
  position: relative;
  padding: 12px;
  display: flex;
  line-height: 22px;
  align-items: center;

  .shop-logo {
    flex-shrink: 0;
    width: 52px;
    height: 52px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: #fff;
  }

  .shop-icon {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.shop-content {
  width: 100%;
  font-size: 13px;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .shop-primary-row {
    display: flex;
    justify-content: space-between;

    .name-block {
      display: flex;
      width: 100%;
      overflow: hidden;
      flex-shrink: 1;
      align-items: center;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-shrink: 1;
        font-size: 16px;
      }
    }
  }

  .shop-sub-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;

    .sub {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 1;
    }

    .icon-nav {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-left: 12px;
      padding: 0 12px;
      border-left: 1px solid #eee;
    }

    .sub-flex-item {
      display: flex;
      align-items: center;
      flex: 1;

      .shop-icon {
        margin-right: 4px;
      }
    }
  }
}
</style>
