var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-adv",class:[
    _vm.$$FormData.image_chamfer,
    _vm.$$FormData.image_style
  ]},[_c('div',{staticClass:"image-adv__background",style:({
      backgroundColor: _vm.$$FormData.background_color,
      height: (_vm.$$FormData.background_height ) * 0.5 + 'px'
    })}),(!_vm.$$FormData.imageList.length)?_c('div',{staticClass:"image-adv__placeholder"},[_c('img',{staticClass:"image-adv__placeholder-img",attrs:{"src":require("@/assets/placeholder-editor-image.png"),"alt":"占位图"}})]):(['single', 'hotarea'].includes(_vm.$$FormData.image_tpl))?[_c('ul',{staticClass:"image-adv__list",style:({
        paddingLeft: (_vm.$$FormData.page_edge ) * 0.5 + 'px',
        paddingRight: (_vm.$$FormData.page_edge ) * 0.5 + 'px'
      })},_vm._l((_vm.$$FormData.imageList),function(item,index){return _c('li',{key:index,staticClass:"image-adv__item",style:({
          marginBottom: `${_vm.$$FormData.image_space / 2}px`
        })},[(item.title && _vm.$$FormData.image_tpl !== 'hotarea')?_c('h3',{staticClass:"image-adv__title"},[_vm._v(_vm._s(item.title))]):_vm._e(),_c('div',{staticClass:"image-adv__image"},[_c('img',{staticClass:"image-adv__image-img",attrs:{"src":item.image.src,"alt":""}})]),(_vm.$$FormData.image_tpl === 'hotarea' && item.hotAreas)?_vm._l((item.hotAreas),function(area){return _c('a',{key:area.__key,staticClass:"image-adv__area",style:({
              transform: `translate(${area.x/2}px, ${area.y/2}px)`,
              width: (area.width/2) + 'px',
              height: (area.height/2) + 'px'
            }),attrs:{"href":area.link ? area.link.value : 'javascript:void(0)'}})}):_vm._e()],2)}),0)]:(['big-slide', 'small-slide', 'nav-slide'].includes(_vm.$$FormData.image_tpl))?[_c('div',{class:[`image-adv__${_vm.$$FormData.image_tpl}`],style:({
        paddingLeft: (_vm.$$FormData.page_edge ) * 0.5 + 'px',
        paddingRight: (_vm.$$FormData.page_edge ) * 0.5 + 'px'
      })},_vm._l((_vm.$$FormData.imageList),function(item,index){return _c('div',{key:index,staticClass:"image-adv__item",style:({
          marginRight: (_vm.$$FormData.image_space ) * 0.5 + 'px',
        })},[(item.title)?_c('h3',{staticClass:"image-adv__title"},[_vm._v(_vm._s(item.title))]):_vm._e(),_c('div',{staticClass:"image-adv__image"},[_c('img',{staticClass:"image-adv__image-img",attrs:{"src":item.image.src,"alt":""}})])])}),0)]:(_vm.$$FormData.image_tpl === 'swiper')?[_c('div',{staticClass:"image-adv__swiper",style:({
        height: (_vm.$$FormData.swiper_height + (_vm.$$FormData.image_style === 'shadow' ? 20 : 0) ) * 0.5 + 'px',
        paddingLeft: (_vm.$$FormData.page_edge ) * 0.5 + 'px',
        paddingRight: (_vm.$$FormData.page_edge ) * 0.5 + 'px',
      })},[_c('swiper',{attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.$$FormData.imageList),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"image-adv__item"},[(item.title)?_c('h3',{staticClass:"image-adv__title"},[_vm._v(_vm._s(item.title))]):_vm._e(),_c('div',{staticClass:"image-adv__image"},[_c('img',{staticClass:"image-adv__image-img",attrs:{"src":item.image.src,"alt":""}})])])])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }