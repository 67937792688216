<template>
  <div class="item-setting">
    <div class="header-setting">
      <div class="header-setting__label">
        <span style="color: red; margin-right: 5px">*</span>
        专题封面
      </div>
      <div class="header-setting__value"></div>
    </div>
    <div class="body-setting">
      <div class="body-setting__tip">
        建议尺寸750*1334或9:16比例尺寸，支持gif，png，jpeg格式，大小不超过2M，此图片用于导购推广时展示的图片
      </div>
      <div class="page-params__upload">
        <image-selector v-model="$$FormData.cover" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageParamsCover'
}
</script>

<style lang="scss" scoped>

</style>
