import SettingTypes from '@/utils/setting-types'

export default {
  name: 'shop-header',
  label: {
    title: '店铺头部',
    icon: require('@/assets/icon-component-label_ShopHeader.svg'),
    limit: 1
  },
  sort: 0,
  data: {
    // 显示内容
    show_content: 'only-shop-name',
    // 是否支持切换门店
    switch_branch: true,
    // 文本颜色
    text_color: '#000',
    // 背景颜色
    background_color: '#fff'
  },
  settings: {
    show_content: {
      type: SettingTypes.select,
      label: '显示内容',
      options: [
        { label: '只显示店铺名称', value: 'only-shop-name' },
        { label: '显示具体位置', value: 'show-address' },
        { label: '显示配送方式', value: 'show-ship-type' },
        { label: '只显示联系方式', value: 'only-contact-way' },
        { label: '显示营业时间', value: 'show-business-time' }
      ]
    },
    switch_branch: {
      type: SettingTypes.radio_button,
      label: '切换门店',
      options: [
        { label: '开启', value: true },
        { label: '关闭', value: false }
      ]
    },
    text_color: {
      type: SettingTypes.color_picker,
      label: '文本颜色',
      default: '#000'
    },
    background_color: {
      type: SettingTypes.color_picker,
      label: '背景颜色',
      default: '#fff'
    }
  }
}
