import SettingTypes from '@/utils/setting-types'
import { clientType } from '@/utils/decor-types'

export default {
  name: 'page-params',
  label: {
    title: '页面参数',
    icon: require('@/assets/icon-accessary_PageParams.png')
  },
  sort: 1,
  data: {
    // 页面名称、标题
    page_name: '页面标题',
    // 页面描述
    page_desc: '',
    // 分享标题
    share_title: '众多劲爆商品正在火热抢购中...',
    // 分享图片
    cover: ''
  },
  settings: {
    page_name: {
      type: SettingTypes.input,
      label: '页面名称',
      props: {
        placeholder: '请填写页面标题，最多50个字符！',
        maxlength: 50
      }
    },
    page_desc: {
      type: SettingTypes.input,
      label: '页面描述',
      props: {
        type: 'textarea',
        placeholder: '填写页面描述可更好的让人了解你的页面！',
        maxlength: 200
      }
    },
    share_title: {
      type: SettingTypes.input,
      label: '分享标题',
      props: {
        type: 'textarea',
        placeholder: '填写分享标题可更好的让人了解你的页面！',
        maxlength: 200
      },
      show: () => clientType.toUpperCase() === 'TOPIC_MOBILE'
    },
    cover: {
      type: SettingTypes.custom,
      label: '专题封面',
      component_name: 'PageParamsCover',
      show: () => clientType.toUpperCase() === 'TOPIC_MOBILE'
    }
  }
}
